import React from "react"
import { useSelector } from "react-redux";
import { ICurrenUserState } from "../store/CurrenUser/current-user.slice";
import { IRootState } from "../store/store";

export const UserCheck: React.FC = () => {

    const user = useSelector<IRootState, ICurrenUserState>((state) => state.CurrenUser);

    return (
        <>
            {
                !user.IdEvtUser &&

                <div className="alert alert-danger m-4">
                    Missing USER
                </div>
            }
        </>
    );
}