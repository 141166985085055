import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { TApiStatus } from "../../../models/TApiStatus";
import { UIAlert } from "../../../Shared/UIAlert";
import { ICurrenUserState } from "../../../store/CurrenUser/current-user.slice";
import { IRootState } from "../../../store/store";
import { removeVote, setVote } from "../../../store/Vote/vote.api";
import { IVoteState } from "../../../store/Vote/vote.slice";
import { VoteAdd } from "./VoteAdd";
import { VoteRemove } from "./VoteRemove";

import * as serviceParam from '../../../BusinessLogic/AWSDigiventsEventParameters';

interface IVoteProps {
    IdPeopleChoice?: string
}

export const Vote: React.FC<IVoteProps> = (props) => {

    const dispatch = useDispatch();


    //Relector
    const user = useSelector<IRootState, ICurrenUserState>((state) => state.CurrenUser);
    const voteStatus = useSelector<IRootState, TApiStatus>((state) => state.CurrentVote.status);
    const { vote, people } = useSelector<IRootState, IVoteState>((state) => state.CurrentVote);

    //Internal state
    const [bAlert, setBAlert] = useState<boolean>(false);
    const [alertText, setAlertText] = useState<string>("Thank you for voting!");
    const [isOpen, setIsOpen] = useState<boolean>(false);

    // Handerl action
    const RemoveVoteHandler = () => {
        if (vote)
            dispatch(removeVote({ idVote: vote.idvotes }));
    }

    const AddVoteHandler = () => {
        if (user.IdEvtUser && props.IdPeopleChoice) {
            dispatch(setVote({ idEvtUser: user.IdEvtUser, idPeople: props.IdPeopleChoice }))

            setAlertText("Thank you for voting!");
            setBAlert(true);
        }
    }

    function VoteRemoveComponent() {
        return vote && people ? <VoteRemove isOpen={isOpen} Peopole={people} RemoveVote={RemoveVoteHandler} /> : VoteAddComponent();
    }

    function VoteAddComponent() {
        return (props.IdPeopleChoice && user.IdEvtUser) && <VoteAdd isOpen={isOpen} VoteAdd={AddVoteHandler} />;
    }

    useEffect(() => {

        const openDate: Date = new Date(Date.UTC(2020, 11, 16, 14, 0, 0));
        const timeToOpen = openDate.getTime() - new Date().getTime();

        let timer: NodeJS.Timeout | null = null;

        if (timeToOpen > 0) {
            timer = setTimeout(() => {
                // apri votazione
                setIsOpen(true);
            }, timeToOpen);
        }
        else {
            (async function () {
                setIsOpen(await serviceParam.getGamificationStatus());
            })();
        }

        return () => { if(timer) clearTimeout(timer); }

    }, []);

    useEffect(() => {
        if (voteStatus === "failed") {
            setAlertText("error during saving!");
            setBAlert(true);
        }
    }, [voteStatus]);


    return (
        <>
            {
                user.IdEvtUser && VoteRemoveComponent()
            }
            <UIAlert
                visible={bAlert}
                title={alertText}
                buttonConfirmText="Ok"
                onClick={() => { setBAlert(false); }}
            />
        </>
    );

}
