import React, { useEffect, useRef } from "react"


import css from './UIConfirm.module.scss';

interface IUIConfirmProps {
    visible: boolean;
    title: string;
    buttonConfirmText?: string;
    buttonCancelText?: string;
    content?: string;
    onClick: (status: boolean) => void;
}


export const UIConfirm: React.VFC<IUIConfirmProps> = ({
    visible,
    title,
    buttonConfirmText = "Si",
    buttonCancelText = "No",
    content,
    onClick
}) => {
    
    useEffect(() => {
        if (visible) {
            refDiv.current?.classList.add(css.enetrBox)

            setTimeout(() => {
                refDiv.current?.classList.remove(css.enetrBox)
            }, 300);
        }
    }, [visible]);


    const refDiv = useRef<HTMLDivElement>(null);
    function onClickBackgroudHandler() {
        refDiv.current?.classList.toggle(css.shakeBox)
        setTimeout(() => {
            refDiv.current?.classList.remove(css.shakeBox)
        }, 450);
    }

    function clickHandler(e: React.MouseEvent<HTMLButtonElement>, stato: boolean) {
        e.preventDefault();
        e.stopPropagation();

        refDiv.current?.classList.toggle(css.exitBox)

        setTimeout(() => {
            onClick(stato);
            refDiv.current?.classList.remove(css.exitBox)
        }, 300);

    }

    return (
        <>
            {
                visible &&
                <div className={css.uiConfirm}>
                    <div className={css.uiConfirmBg}></div>

                    <div className={css.uiContent} onClick={onClickBackgroudHandler}>
                        <div>
                            <div ref={refDiv} className={css.confirmBox} >
                                <div className={css.title}>{title}</div>

                                {content &&
                                    <div
                                        className={css.content}
                                        dangerouslySetInnerHTML={{ __html: content }}></div>
                                }

                                <div className={css.contentButton}>
                                    <button
                                        type="button"
                                        onClick={(e) => clickHandler(e, false)}>{buttonCancelText}</button>
                                    <button
                                        type="button"
                                        onClick={(e) => clickHandler(e, true)}>{buttonConfirmText}</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            }
        </>
    );
}
