import { createAsyncThunk } from "@reduxjs/toolkit";
import { IPeopleChoiceVote } from "../../models/IPeopleChoiceVote";
import { IPeopleChoiceData } from "../../models/IPeopoeChiceData";

import * as pcServiceVote from '../../BusinessLogic/AWSPeopleChoiceVoteServive';
import * as pcServiceData from '../../BusinessLogic/AWSPeopleChoiceDataServive';

type T_ApiError = {
    message: string;
};


interface IApiVote {
    Vote: IPeopleChoiceVote | null,
    People: IPeopleChoiceData | null
}

// creo un async trunk usando createAsyncThunk
export const getVote = createAsyncThunk<

    // type arguements
    // 1. Tipo restituito
    // 2. tipo del payload
    // 3. errore
    IApiVote,
    { idEvtUser: number },
    { rejectValue: T_ApiError }
>("vote/getVote", async (payload, thunkApi) => {

    try {
        // hit endpoint using axios
        const respVote = (await pcServiceVote.findByIdUser(payload.idEvtUser)) as IPeopleChoiceVote;

        const result: IApiVote = {
            Vote: null,
            People: null
        };

        if (respVote) {
            result.Vote = respVote;
            result.People = await pcServiceData.load(respVote.idpeoplechoice);
        }

        return result;
    }
    catch (err) {
        console.log("error.[vote/getVote]")
        console.log(err);

        return thunkApi.rejectWithValue({
            message: "Failed to fetch people data.",
        });
    }
});

export const setVote = createAsyncThunk<

    // type arguements
    // 1. Tipo restituito
    // 2. tipo del payload
    // 3. errore
    IApiVote,
    { idEvtUser: number, idPeople: string },
    { rejectValue: T_ApiError }
>("vote/setVote", async (payload, thunkApi) => {

    try {
        // hit endpoint using axios
        const response = await pcServiceVote.addVote(payload.idEvtUser, payload.idPeople);

        if (!response) {
            return thunkApi.rejectWithValue({
                message: "error during save",
            });
        }
        else {

            const result: IApiVote = {
                Vote:response,
                People: (await pcServiceData.load(payload.idPeople))
            };

            return result;

        }
    }
    catch (err) {
        console.log("error.[vote/setVote]")
        console.log(err);

        return thunkApi.rejectWithValue({
            message: "error during save",
        });
    }

});

export const removeVote = createAsyncThunk<

    // type arguements
    // 1. Tipo restituito
    // 2. tipo del payload
    // 3. errore
    void,
    { idVote: string },
    { rejectValue: T_ApiError }
>("vote/removeVote", async (payload, thunkApi) => {

    try {
        // hit endpoint using axios
        const response = await pcServiceVote.removeVote(payload.idVote);

        if (!response) {
            return thunkApi.rejectWithValue({
                message: "error during save",
            });
        }
         
    }
    catch (err) {
        console.log("error.[vote/removeVote]")
        console.log(err);
        
        return thunkApi.rejectWithValue({
            message: "error during save",
        });
    }

});