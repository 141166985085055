import React, { useEffect, useState } from "react"
import { NavLink } from "react-router-dom";
import Switch from "react-switch";

import * as serviceParam from '../../BusinessLogic/AWSDigiventsEventParameters';

export const ControlPanelPage: React.FC = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);


    useEffect(() => {
        (async function () {
            setIsOpen(await serviceParam.getGamificationStatus());
        })();
    }, []);

    const handleChange = async (checked: boolean) => {
        let tmp = await serviceParam.setGamificationStatus(checked);
        setIsOpen(tmp);
    }

    return (
        <>

            <nav className="navbar navbar-light bg-light">
                <div className="container">
                    <a className="navbar-brand" href="#!">Crif Gamification</a>
                </div>
            </nav>

            <div className="container">
                <div className="row mt-5">
                    <div className="col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Votazione
                                    {
                                        isOpen
                                            ? <span className="float-right badge badge-pill badge-success">Aperta</span>
                                            : <span className="float-right badge badge-pill badge-danger">Chiusa</span>
                                    }
                                </h5>
                                <p className="card-text">&nbsp;</p>

                                <div className="text-center">
                                    <span style={{ position: 'relative', bottom: '10px', marginRight: '20px' }}>Cambia lo stato:</span>
                                    <label>
                                        <Switch onChange={handleChange} checked={isOpen} />
                                    </label>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Classifica</h5>
                                <p className="card-text">Visualizza i risultati</p>
                                <NavLink end to="/ranking" className="btn btn-primary float-right">
                                    Go to Ranking
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}