import AWS from "aws-sdk";
import { DocumentClient } from "aws-sdk/clients/dynamodb";
import { IPeopleChoiceData } from "../models/IPeopoeChiceData";
import { IPeopoeChiceRanking } from "../models/IPeopoeChiceRanking";
import awsConfig from "./aws.setting";

// data
const TB_NAME_DATA = "1632_peoplechoice_data";
const TB_NAME_VOTES = "1632_peoplechoice_votes";

AWS.config.update(awsConfig);
const docClient = new AWS.DynamoDB.DocumentClient();

const getRanking = async () => {

    let itemsWithVotes: IPeopoeChiceRanking[] = [];

    let d = await docClient.scan({ TableName: TB_NAME_DATA }).promise();
    d.Items as IPeopleChoiceData[];

    if (d.Items) {

        itemsWithVotes = await Promise.all(
            (d.Items as IPeopleChoiceData[])
                .map(async (x) => {

                    let params: DocumentClient.ScanInput = {
                        TableName: TB_NAME_VOTES,
                        ExpressionAttributeValues: { ":idPC": x.idpeoplechoice },
                        FilterExpression: "idpeoplechoice = :idPC",
                    };

                    let totVotes = 0;
                    let lastKey = null;

                    do
                    {
                        if(lastKey)
                            params.ExclusiveStartKey = lastKey;

                        let votesResult = await docClient.scan(params).promise();

                        lastKey = votesResult.LastEvaluatedKey;
                        totVotes += votesResult.Count || 0;
                    }
                    while(lastKey)

                    return {
                        ...x,
                        votes: totVotes
                    };
                })
        );
    }

    return itemsWithVotes;
};


export { getRanking }