import React, { useState } from "react"
import { UIConfirm } from "../../../Shared/UIConfirm";
import { IPeopleChoiceData } from "../../../models/IPeopoeChiceData";

import css from './VoteRemove.module.scss';

type VoteRemoveProps = {
    isOpen: boolean,
    Peopole: IPeopleChoiceData,
    RemoveVote: () => void;
}

export const VoteRemove: React.VFC<VoteRemoveProps> = (props) => {

    const [bConfirm, setBConfirm] = useState<boolean>(false);

    const confirmOnClick = async (confirmState: boolean) => {

        setBConfirm(false);

        if (confirmState) {
            //Remove Vote
            props.RemoveVote();
        }
    };

    return (
        <>
            <div className="row mt-2">
                <div className="col-lg-12 text-center">
                    <div className={css.attributo}>
                        <h4>
                            <span className="text-color" style={{ fontWeight: "bold!important", display: 'block' }}>
                                Your preference is
                                <br />
                                {props.Peopole.FullName}
                            </span>

                             { props.isOpen && <button className="btn btn-danger mt-4" onClick={() => setBConfirm(true)} >REMOVE PREFERENCE</button> }
                        </h4>
                    </div>
                </div>
            </div>

            <UIConfirm
                visible={bConfirm}
                title="Do you want remove your preference?"
                buttonConfirmText="Yes"
                buttonCancelText="No"
                onClick={confirmOnClick}
            />

        </>
    );
}