import React, { useState } from "react"

import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { UIConfirm } from "../../../Shared/UIConfirm";

type VoteAddProps = {
    isOpen: boolean;
    VoteAdd: () => void;
}

export const VoteAdd: React.FC<VoteAddProps> = ({ VoteAdd, isOpen }) => {

    const [bConfirm, setBConfirm] = useState<boolean>(false);

    const confirmOnClick = async (statoConfirm: boolean) => {

        setBConfirm(false);

        if (statoConfirm) {
            VoteAdd();
        }
    };

    return (
        <>
            {
                isOpen &&
                <div className="row mt-2">
                    <div className="col-lg-12 text-center">
                        <button
                            onClick={() => setBConfirm(true)}
                            className="btn btn-custom btn-lg">
                            <FontAwesomeIcon
                                icon={faStar}
                                className="" />
                            &nbsp;Vote
                        </button>
                    </div>
                </div>
            }

            <UIConfirm
                visible={bConfirm}
                title="Do you confirm your vote?"
                buttonConfirmText="Yes"
                buttonCancelText="No"
                onClick={confirmOnClick}
            />

        </>
    );
}