import { createSlice } from "@reduxjs/toolkit";
import { IPeopleChoiceVote } from "../../models/IPeopleChoiceVote";
import { IPeopleChoiceData } from "../../models/IPeopoeChiceData";
import { TApiStatus } from "../../models/TApiStatus";
import { getVote, removeVote, setVote } from "./vote.api";


// Type initialState
export interface IVoteState {
    people: IPeopleChoiceData | null;
    vote: IPeopleChoiceVote | null;
    status: TApiStatus;
    error: string | null;
}

// Set initialState
const initialState: IVoteState = {
    people: null,
    vote: null,
    status: "idle",
    error: null,
};

// create weather slice
export const voteSlice = createSlice({
    name: "vote",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // when send a request, fetchAllPeople is pending
        builder.addCase(setVote.pending, (state) => {
            // change status to loading and clear previous errors
            state.status = "loading";
            state.error = null;
        });

        // when server responses with data, fetchAllPeople is fullfilled
        builder.addCase(setVote.fulfilled, (state, { payload }) => {
            // change status back to idle and add forecast to forecasts state
            state.people = payload.People;
            state.vote = payload.Vote;
            state.status = "succeeded";
        });

        // when server responses with error, fetchAllPeople is rejected
        builder.addCase(setVote.rejected, (state, { payload }) => {
            console.log("rejected", payload);
            // change status back to idle and add error to state
            if (payload) state.error = payload.message;
            state.status = "failed";
        });

        // when send a request, fetchAllPeople is pending
        builder.addCase(getVote.pending, (state) => {
            // change status to loading and clear previous errors
            state.status = "loading";
            state.error = null;
        });

        // when server responses with data, fetchAllPeople is fullfilled
        builder.addCase(getVote.fulfilled, (state, { payload }) => {
            // change status back to idle and add forecast to forecasts state
            console.log(payload);
            state.people = payload.People;
            state.vote = payload.Vote;
            state.status = "succeeded";
        });

        // when server responses with error, fetchAllPeople is rejected
        builder.addCase(getVote.rejected, (state, { payload }) => {
            console.log("rejected", payload);
            // change status back to idle and add error to state
            if (payload) state.error = payload.message;
            state.status = "failed";
        });

        // when send a request, fetchAllPeople is pending
        builder.addCase(removeVote.pending, (state) => {
            // change status to loading and clear previous errors
            state.status = "loading";
            state.error = null;
        });

        // when server responses with data, fetchAllPeople is fullfilled
        builder.addCase(removeVote.fulfilled, (state, { payload }) => {
            // change status back to idle and add forecast to forecasts state
            state.people = null;
            state.vote = null;
            state.status = "succeeded";
        });

        // when server responses with error, fetchAllPeople is rejected
        builder.addCase(removeVote.rejected, (state, { payload }) => {
            console.log("rejected", payload);
            // change status back to idle and add error to state
            if (payload) state.error = payload.message;
            state.status = "failed";
        });
    },
});

export const voteReducer = voteSlice.reducer;