import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom";
import { IPeopleChoiceData } from "../../models/IPeopoeChiceData";
import css from './DetailPage.module.scss'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { Vote } from "./Components/Vote";
import { useSelector } from "react-redux";
import { IRootState } from "../../store/store";

import { useNavigate } from "react-router-dom";
import { LayoutMaster } from "../../Shared/LayoutMaster";
import { ImageList } from "./Components/ImageList";

export const DetailPage: React.FC = () => {

    const { itemId } = useParams();
    const navigate = useNavigate();
    const [item, setItem] = useState<IPeopleChoiceData>();

    const list = useSelector<IRootState, IPeopleChoiceData[]>(state => state.People.people);

    useEffect(() => {

        let tmp = list.find(x => x.idpeoplechoice === itemId);

        if (tmp && tmp.idpeoplechoice !== '') {
            setItem(tmp);
        }
        else {
            console.log("goto");
            navigate("/");
        }

    }, [itemId, list, navigate]);

    return (
        <>
            <LayoutMaster>
                <div className={css.divTop}>
                    <Link to="/" className={css.btnBack}>
                        <FontAwesomeIcon icon={faAngleLeft} size="3x" />
                    </Link>
                </div>

                <Vote IdPeopleChoice={itemId} />

                <h3 className={css.pageTitle}>
                    {item?.FullName}
                </h3>

                {
                    item?.Images && <ImageList images={item?.Images} />
                }

                <div className={css.dettaglio}>

                    {/* <p>
                            <strong>This is how I unlocked my potential:</strong>
                            <br />
                             </p> */}
                    <ul className="m-0" style={{ paddingLeft: '20px' }}>
                        {
                            item?.BulletedList.map((b, index) => {
                                return <li
                                    key={index}
                                    dangerouslySetInnerHTML={{ __html: b }} />
                            })
                        }
                    </ul>
                </div>

            </LayoutMaster>
        </>
    );
}