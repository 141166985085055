import AWS from "aws-sdk";
import { DocumentClient } from "aws-sdk/clients/dynamodb";
import { IDigiventsEventParameters } from "../models/IDigiventsEventParameters";

import awsConfig from "./aws.setting";

const TB_NAME = 'digivents_event_parameters'

AWS.config.update(awsConfig);
const docClient = new AWS.DynamoDB.DocumentClient();

const load = async (param: string) => {

    let params: DocumentClient.GetItemInput = {
        TableName: TB_NAME,
        Key: {
            "params": param
        }
    };

    let d = await docClient.get(params).promise()
    return d.Item as IDigiventsEventParameters;
};

const update = async (param: string, value: any) => {

    let params: DocumentClient.UpdateItemInput = {
        TableName: TB_NAME,
        Key: {
            "params": param
        },
        ExpressionAttributeNames: {'#val': 'value'},
        UpdateExpression: "set #val = :v",
        ExpressionAttributeValues: {
            ":v": value,
        },
        ReturnValues: "UPDATED_NEW"
    };

    await docClient.update(params).promise()
  
    return await load(param) as IDigiventsEventParameters;
};


const getGamificationStatus = async () => {

    const PARAM = '1632_GAMIFICATION_STATUS';

    let prm = (await load(PARAM)).value;
    return prm;
}

const setGamificationStatus = async (stato: boolean) => {

    const PARAM = '1632_GAMIFICATION_STATUS';

    let prm = (await update(PARAM, stato)).value;
    return prm;
}

export {
    // load,
    // update,
    getGamificationStatus, setGamificationStatus
}