
type AwsConfigType = {
    accessKeyId: string;
    secretAccessKey: string;
    region: string;
};

const awsConfig: AwsConfigType= {
    accessKeyId: "AKIAQ7GNS64GF2OGM2JB",
    secretAccessKey: "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",
    region: "eu-west-1"
}

export default awsConfig;