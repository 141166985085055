import { createAsyncThunk } from "@reduxjs/toolkit";
import { IPeopleChoiceData } from "../../models/IPeopoeChiceData";
import * as pcServiceData from '../../BusinessLogic/AWSPeopleChoiceDataServive';
import _ from 'lodash';


type T_ApiError = {
    message: string;
};


// creo un async trunk usando createAsyncThunk
export const fetchAllPeople = createAsyncThunk<

    // type arguements
    // 1. Tipo restituito
    // 2. tipo del payload
    // 3. errore
    IPeopleChoiceData[],
    void,
    { rejectValue: T_ApiError }
>("people/fetchAllPeople", async (payload, thunkApi) => {

    console.log("--- fetchAllPeople ---")
    try {
        // hit endpoint using axios
        const response = _.orderBy((await pcServiceData.getAll()), [function (o) { return o.Order }]);

        return response as IPeopleChoiceData[];
    }

    catch (err) {
        console.log("rejected!!");
        return thunkApi.rejectWithValue({
            message: "Failed to fetch people data.",
        });
    }

});