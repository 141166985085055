import AWS from "aws-sdk";
import { DocumentClient } from "aws-sdk/clients/dynamodb";
import { v4 as uuidv4 } from 'uuid';
import { IPeopleChoiceVote } from "../models/IPeopleChoiceVote";
import awsConfig from "./aws.setting";

const TB_NAME = '1632_peoplechoice_votes'

AWS.config.update(awsConfig);
const docClient = new AWS.DynamoDB.DocumentClient();

const findByIdUser = async (idUser: number) => {

    let params: DocumentClient.ScanInput = {
        TableName: TB_NAME,
        ExpressionAttributeValues: { ':idU': idUser },
        FilterExpression: 'iduser = :idU',

    };

    let d = await docClient.scan(params).promise()
    return (d.Items as IPeopleChoiceVote[])[0];
};


const addVote = async (idUtente: number, idPeopleChoice: string)  : Promise<IPeopleChoiceVote>   => {

    //  try {
        const addItem: IPeopleChoiceVote = {
            idvotes: uuidv4(),
            iduser: idUtente,
            idpeoplechoice: idPeopleChoice
        };

        let params: DocumentClient.PutItemInput = {
            TableName: TB_NAME,
            //ExpressionAttributeNames: { '#d': 'domain' },
            //ConditionExpression: 'attribute_not_exists(#d)',
            Item: addItem
        };

        await docClient.put(params).promise();

        return addItem;
    // }
    // catch (error) {
    //     console.log(error);
    //     return null;
    // }

}


const removeVote = async (idvote: string) => {
    try {

        let paramSearch: DocumentClient.DeleteItemInput = {
            TableName: TB_NAME,
            Key: {
                "idvotes": idvote
            }
        };

        await docClient.delete(paramSearch).promise();

        return true;
    }
    catch (error) {
        console.log(error);
        return false;
    }
}


export {
    findByIdUser,
    addVote,
    removeVote
}