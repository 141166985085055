import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { DetailPage } from './pages/Detail/DetailPage';
import { ListPage } from './pages/List/ListPage';
import { useDispatch } from 'react-redux';
import { setUser } from './store/CurrenUser/current-user.slice';
import { fetchAllPeople } from './store/People/people.api';
import { getVote } from './store/Vote/vote.api';
import { RankingPage } from './pages/Ranking/RankingPage';
import { ControlPanelPage } from './pages/ControlPanel/ControlPanelPage';



function App() {
 
  const urlParams = new URLSearchParams(window.location.search);

  const dispatch = useDispatch();

  let idUser: number = 0;
  if (urlParams.has('DIGIVENTS_LOGGED_USER_ID')) {
    idUser = parseInt(urlParams.get('DIGIVENTS_LOGGED_USER_ID') as string);

    dispatch(setUser(idUser));
  }

  //Load data from server
  useEffect(() => {
    dispatch(fetchAllPeople());

    if (idUser)
      dispatch(getVote({ idEvtUser: idUser }));

  }, [idUser, dispatch]);


  return (

    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ListPage />} />
        <Route path="/detail" >
          <Route
            index
            element={<Navigate replace to="/" />}
          />
          <Route path=":itemId" element={<DetailPage />}>
          </Route>
        </Route>
        <Route path="/ranking" element={<RankingPage />} ></Route>
        <Route path="/controlpanel" element={<ControlPanelPage />} ></Route>
        <Route path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
