import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ICurrenUserState {
    IdEvtUser: number | null
}

const initialState: ICurrenUserState = {
    IdEvtUser: null
}

export const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<number>) => {

            if (action.payload)
                state.IdEvtUser = action.payload;
            else
                state.IdEvtUser = null;
                
        },
        reset: state => {
            state.IdEvtUser = null;
        }

    }

})

//Esporto le action
export const { setUser, reset } = currentUserSlice.actions;

//Esport il reducer
export const currentUserReducer = currentUserSlice.reducer;