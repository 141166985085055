import { createSlice } from "@reduxjs/toolkit";
import { IPeopleChoiceData } from "../../models/IPeopoeChiceData";
import { TApiStatus } from "../../models/TApiStatus";
import { fetchAllPeople } from "./people.api";

// Type initialState
export interface IPeopleState {
    people: IPeopleChoiceData[];
    status: TApiStatus;
    error: string | null;
}

// Set initialState
const initialState: IPeopleState = {
    people: [],
    status: "idle",
    error: null,
};

// create weather slice
export const peopleSlice = createSlice({
    name: "people",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // when send a request, fetchAllPeople is pending
        builder.addCase(fetchAllPeople.pending, (state) => {
            // change status to loading and clear previous errors
            state.status = "loading";
            state.error = null;
        });

        // when server responses with data, fetchAllPeople is fullfilled
        builder.addCase(fetchAllPeople.fulfilled, (state, { payload }) => {
            // change status back to idle and add forecast to forecasts state
            state.people = payload;
            state.status = "succeeded";
        });

        // when server responses with error, fetchAllPeople is rejected
        builder.addCase(fetchAllPeople.rejected, (state, { payload }) => {
            console.log("rejected", payload);
            // change status back to idle and add error to state
            if (payload) state.error = payload.message;
            state.status = "failed";
        });
    },
});

export const peopleReducer =  peopleSlice.reducer;