import React, { useState } from "react"
import cn from 'classnames'
import css from './ImageList.module.scss';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

interface ImageListProps {
    images: string[];
}

export const ImageList: React.VFC<ImageListProps> = ({ images }) => {


    const [isOpen, setIsOpen] = useState<Boolean>(false);
    const [photoIndex, setPhotoIndex] = useState<number>(0);

    /* https://www.npmjs.com/package/react-image-lightbox => libreria per zoom image */

    return (
        <>
            <div className={css.dvImages}>
                {
                    images.map((img, index) => {
                        return <img
                            key={index}
                            src={img}
                            className={cn('img-thumbnail', css.elementImg)}
                            onClick={() => {
                                setPhotoIndex(index);
                                setIsOpen(true);
                            }}
                            alt={img} />
                    })
                }
            </div>

            {isOpen && (
                <Lightbox
                    enableZoom={false}
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                />
            )}

        </>
    );
}