import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import cn from 'classnames'
import css from './ListPage.module.scss'
import { IPeopleChoiceData } from "../../models/IPeopoeChiceData";
import { UserCheck } from "../../Shared/UserCheck";
import { useSelector } from "react-redux";
import { IRootState } from "../../store/store";
import { IPeopleState } from "../../store/People/people.slice";
import { LayoutMaster } from "../../Shared/LayoutMaster";

export const ListPage: React.FC = () => {

    const [dataFilter, setDataFilter] = useState<IPeopleChoiceData[]>([]);
    const [searchText, setSearchText] = useState<string>('');

    function changeHandler(e: React.ChangeEvent<HTMLInputElement>) {
        setSearchText(e.target.value);
    }

    const people = useSelector<IRootState, IPeopleState>((store) => store.People);
    const currentVoteId = useSelector<IRootState, string | undefined>((store) => store.CurrentVote.vote?.idpeoplechoice);

    useEffect(() => {

        if (searchText.length === 0)
            setDataFilter(people.people)
        else
            setDataFilter(people.people.filter(x => (`${x.FullName}`)?.toString().toLowerCase().includes(searchText.toLowerCase())));

    }, [searchText, people.people]);

    useEffect(() => {
        setDataFilter(people.people);
    }, [people]);

    return (
        <>
            <LayoutMaster>
                <UserCheck />

                <h3 className={cn("mt-2", css.title)}>COLLEAGUE AWARDS</h3>

                <div>
                    <input
                        onChange={changeHandler}
                        className="form-control form-inline"
                        placeholder="search..."
                        style={{ margin: '10px auto' }} />

                    <ul className={css.listPeopleChoice}>
                        {
                            dataFilter.map((item => {
                                return (
                                    <li key={item.idpeoplechoice}>
                                        <Link
                                            to={`/detail/${item.idpeoplechoice}`}
                                            className={css.btnRow}>

                                            <div className={css.attributo}>
                                                <table className={css.tbItem}>
                                                    <tbody>
                                                        <tr>
                                                            <td className={css.tdInfo}>
                                                                {item.idpeoplechoice === currentVoteId && (
                                                                    <FontAwesomeIcon
                                                                        icon={faStar}
                                                                        className={cn("float-right", css.iconStar)} />
                                                                )}
                                                                <h4 className="mb-0">{item.FullName}</h4>
                                                                {/* <p className="m-0">Nationality: {item.Nation}</p> */}
                                                            </td>
                                                            <td className={css.tdArrow}>
                                                                <img src="https://m.digivents.net/event/images/freccialista.png" alt="" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </Link>
                                    </li>
                                );
                            }))
                        }
                    </ul>
                </div>
            </LayoutMaster>
        </>
    );
}