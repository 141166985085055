import AWS from "aws-sdk";
import { DocumentClient } from "aws-sdk/clients/dynamodb";
import { IPeopleChoiceData } from "../models/IPeopoeChiceData";

import awsConfig from "./aws.setting";

const TB_NAME = '1632_peoplechoice_data'

AWS.config.update(awsConfig);
const docClient = new AWS.DynamoDB.DocumentClient();

const getAll = async ()   => {
    let d = await docClient.scan({ TableName: TB_NAME }).promise()
    return d.Items as IPeopleChoiceData[];
};

const load = async (id: string)   => {

    let params: DocumentClient.GetItemInput = {
        TableName: TB_NAME,
        Key: {
            "idpeoplechoice": id
        }
    };

    let d = await docClient.get(params).promise()
    return d.Item as IPeopleChoiceData;

};

export {
    getAll,
    load
}