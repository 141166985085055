import React, { useEffect, useState } from "react"
import * as pcServiceRanking from '../../BusinessLogic/AWSPeopleChoiceRankingServive'
import useBodyClass from "../../Hooks/bodyHoos";
import { IPeopoeChiceRanking } from "../../models/IPeopoeChiceRanking";
import css from './RankingPage.module.scss';
import _ from 'lodash';

export const RankingPage: React.FC = () => {

    const [data, setData] = useState<IPeopoeChiceRanking[]>([]);
    const [max, setMax] = useState<number>(0);

    useBodyClass(css.bodyClass);

    useEffect(() => {
        console.log("init ranking page");

        (async function () {
            let tmp = _.orderBy((await pcServiceRanking.getRanking()), [function (o) { return o.votes }], "desc");

            console.log(tmp);
            setData(tmp);

            if (tmp.length > 0)
                setMax(tmp[0].votes);
        })();

    }, []);

    return (
        <>


            <div className={css.wrapperRanking}>

                <h1 className="text-center my-3">COLLEAGUE AWARDS</h1>

                <div className={css.lboardWrap}>
                    <div className={css.lboardItem}>


                        {
                            data.map((r, index) => {

                                // let perc: number = 0;
                                // if(r.votes){
                                //     perc = (100/8) * (data.length - index);
                                // }
                                let perc: number = 0;
                                if (r.votes) {
                                    perc = (100 * r.votes) / max;
                                }

                                return (
                                    <div className={css.lboardMem} key={index}>
                                        <div className={css.Img}>
                                            <img src={r.Images[0]} alt={r.Images[0]} />
                                        </div>
                                        <div className={css.nameBar}>
                                            <h4><span>{index + 1}.</span>{r.FullName}</h4>
                                            <div className={css.barWrap}>
                                                <div className={css.innerBar} style={{ width: `${perc}%` }} />
                                            </div>
                                        </div>
                                        <div className={css.points}>
                                            {r.votes} votes
                                        </div>
                                    </div>
                                )
                            })
                        }



                        {/* <div className="lboard_mem">
                            <div className="img">
                                <img src="pic_4.png" alt="picture_4" />
                            </div>
                            <div className="name_bar">
                                <p><span>2.</span>Rosey</p>
                                <div className="bar_wrap">
                                    <div className="inner_bar" style={{ width: '85%' }} />
                                </div>
                            </div>
                            <div className="points">
                                2185 points
                            </div>
                        </div> */}



                    </div>
                </div>

            </div>

        </>
    );
}